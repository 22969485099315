import React from "react";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';
import Wave from 'react-wavify';
import { Slide } from 'react-reveal';
import TeamCard from "../components/team";
import Collapse,{ CollapseItem} from "../components/collapse";



function Team(){
        return(
        <Layout>
            <Helmet>
                <title>Om oss | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Crediflow på ett enkelt och effektivt sätt hanterar både dina utgående och inkommande fakturor oavsett format." />
                <meta name="keywords" content="Om Crediflow | Vilka är Crediflow" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="Vi är Crediflow" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="http://www.crediflow.se/om-oss" />
                <link rel="cannonical" href="http://www.crediflow.se/om-oss" />
            </Helmet>


            <section className="bg-gray-100 pt-4">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="bg-team-banner bg-cover bg-no-repeat bg-center rounded-lg overflow-hidden">
                        <div className="w-full h-full" style={{background: "rgba(29, 95, 182, 0.05)"}}>
                            <h1 className="pt-40 pb-32  text-4xl lg:text-5xl font-bold text-white text-center">Crediflow</h1>
                            <Wave 
                                className="-mb-2"
                                fill='#F3F4F6'
                                paused={false}
                                options={{
                                height: 20,
                                amplitude: 50,
                                speed: 0.15,
                                points: 3
                                }}
                            />  
                        </div>
                    </div>
                </div>
            </section>

            {/***********  About Section - START  **********/}
            <section className="bg-gray-100 py-24">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid xl:grid-cols-2 gap-32 lg:gap-20">
                        <Slide left>
                        <div className="w-full h-full">
                            <h2 className="title-font font-semibold text-3xl text-gray-900">Kan ett modernt företag ha över 25 års erfarenhet?</h2>
                            <p className="leading-relaxed mt-6 text-gray-600">
                            Svaret är enkelt &#8211; JA! Vi har över 25 års erfarenhet och 
                            har med våra leveranser till offentlig myndighet så 
                            väl som till det lilla bolaget en enorm erfarenhet av 
                            den marknaden vi verkar på.
                            <br/><br/>
                            Vår koncern omsätter idag närmare 100 miljoner per år 
                            och har Tripple AAA rating sedan flera år tillbaka, 
                            vilket innebär att du får en finansiellt stark leverantör 
                            som också älskar teknik. Våra kunder får alltså ta del 
                            av den modernaste teknologin som finns på marknaden 
                            så som AI, machine learning RPA etc.
                            <br/><br/>
                            Vi vill göra din resa inom digitalisering enkel, låt 
                            oss ta med dig in i framtiden. Visste du att vi 
                            dessutom sparar pengar åt våra kunder och partners?
                            </p>  

                            <div className="w-full mt-8">
                                <Collapse collapse_text="Policy">
                                    <CollapseItem 
                                        href="pdf/policy/informationssakerhetspolicy" 
                                        pdf_name="Informationssäkerhetspolicy"
                                    />
                                    <CollapseItem 
                                        href="pdf/policy/kvalitetspolicy" 
                                        pdf_name="Kvalitetspolicy"
                                    />
                                    <CollapseItem 
                                        href="pdf/policy/miljopolicy" 
                                        pdf_name="Miljöpolicy"
                                    />
                                </Collapse>   
                            </div>
                            <div className="w-full mt-8">
                                <Collapse collapse_text="Villkor och Integritet">
                                    <CollapseItem 
                                        href="pdf/villkor/anvandarvillkor" 
                                        pdf_name="Användarvillkor"
                                    />
                                    <CollapseItem 
                                        href="pdf/villkor/integritetspolicy" 
                                        pdf_name="Integritetspolicy"
                                    />
                                </Collapse>   
                            </div>
                          
                        </div>
                        </Slide>
                        <Slide right>
                            <div className="">
                            <div className="w-full mx-auto flex items-start justify-center bg-shape bg-no-repeat bg-contain bg-top">
                                <div className="w-full max-w-sm mt-14 lg:mt-0 flex justify-center items-center" >
                                    <div className="w-10/12 transform rotate-1 sm:rotate-3 ">
                                        <div className="custom_3d_img transform -skew-x-2  sm:-skew-x-6 pr-3 pb-3 bg-gradient-to-br from-blue-400 to-blue-900" >
                                            <div className="bg-gradient-to-tl from-gray-400 to-blue-800 rounded-3xl pb-3 pt-2 pl-1 pr-2">
                                                <div className="bg-white rounded-3xl overflow-hidden" style={{height: "550px"}}>
                                                    <div className="h-full w-full flex flex-col justify-between">
                                                        <iframe src="https://bot.leadoo.com/bot/inpage.html?code=nSEzKskQ" style={{border: "none", width: "100%", height: "380px"}} title="chatbot crediflow"/>
                                                        <div className="w-full h-40 m-1.5 bg-no-repeat bg-keyboard bg-contain bg-bottom border-t border-gray-200"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            
                        </Slide>
                    </div>
                </div>
            </section>
            {/***********  About Section - END  **********/}

            <section className="bg-gray-100 py-16">
                <div className="container mx-auto px-4 xl:px-24">
                    <hr className="text-gray-200"/>
                </div>
            </section>

            {/***********  Our Team - START  **********/}
            <section className="bg-gray-100 pt-20 pb-24 ">
                <div className="container mx-auto px-4 xl:px-24">
                    <h1 className="text-center text-blue-custome title-font font-semibold text-3xl">Vårt team</h1>
                    <p className="mt-4 text-center text-gray-600 lg:w-1/2 mx-auto">Det är oss du kommer möta när du väljer Crediflow!</p>
                </div>
            </section>

            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-12 ">
                          <TeamCard 
                            name="Ann-Kristine Pettersson"
                            title="Front-office växel"
                            number="08-578 604 00"
                        />
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Sälj</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <TeamCard 
                            name="Anders Persson"
                            title="Partnersäljare indata"
                            number="08-578 604 05"
                        />
                        <TeamCard 
                            name="Christian Krutzfeldt"
                            title="Partnersäljare Utdata"
                            number="042-125 453"
                        />
                    </div>
                </div>
            </section>




            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Support Stockholm</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <TeamCard 
                            name="Nicklas Nylén"
                            title="Support Stockholm"
                        />
                        <TeamCard 
                            name="Kjell Bergendahl"
                            title="Support 2-line Stockholm"
                        />
                        <TeamCard 
                            name="Adam Fins"
                            title="Support Stockholm"
                        />
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Support Helsingborg</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <TeamCard 
                            name="Felix Oxing"
                            title="Support Helsingborg"
                        />
                        <TeamCard 
                            name="Jonas Schrewelius"
                            title="Support Helsingborg"
                        />
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Projekt</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <TeamCard 
                            name="Anders Staaf"
                            title="Projektledare"
                            number="08-578 604 02"
                        />
                        <TeamCard 
                            name="Mari Dauner"
                            title="Produktägare CS eVoyce"
                            number="08-578 604 24"
                        />
                        <TeamCard 
                            name="Pia Sandhagen"
                            title="Projektledare"
                            number="08-578 604 48"
                        />
                    </div>
                </div>
            </section>




            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Utveckling Stockholm</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">Daniel S</h1>
                        </div>

                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">Stefan H</h1>
                        </div>

                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">Gustav E</h1>
                        </div>

                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">Erik W</h1>
                        </div>
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Utvecklare utomlands</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">Sigitas L</h1>
                        </div>
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Utvecklare Helsingborg</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">Joakim P</h1>
                        </div>
                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">John T</h1>
                        </div>
                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">David H</h1>
                        </div>
                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">Elinore E</h1>
                        </div>
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Ekonomi</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <TeamCard 
                            name="Peter Larsson "
                            title="CFO"
                            number="072-560 48 88"
                        />
                    </div>
                </div>
            </section>


            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Ledning Sverige</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <TeamCard 
                            name="Niklas Horn Lundberg"
                            title="VD"
                        />
                    </div>
                </div>
            </section>


            <section className="bg-gray-100 pt-12 pb-32">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Operativ Chef Syd</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <TeamCard 
                            name="Georgios Vasiliagos"
                            title="Vice VD / Operativ chef Syd"
                            number="042-127 290"
                        />
                    </div>
                </div>
            </section>
   
        </Layout>
    );
}
export default Team;